<bolt-waiting-indicator id="requestIndicator" fullscreen starthidden></bolt-waiting-indicator>
<div class="bolt-container-fluid" style="padding-bottom: 50px">
  <div class="bolt-row">
    <div class="bolt-col-sm-3">
      <app-nav (nav)="nav($event)" (urlPath)="urlPath($event)"></app-nav>
    </div>
    <div class="bolt-col">
      <div *ngIf="categories">
        <div *ngIf="!readOnly; else displayAlert">
          <app-button-bar
            [buttonBar]="buttonBar"
            [revisionBar]="revisionBar"
            [product]="product"
            (saved)="save($event)"
            (edit)="edit($event)"
            (delete)="delete($event)"
          ></app-button-bar>
        </div>

        <ng-template #displayAlert>
          <bolt-button-bar class="bolt-background-white">
            <bolt-button slot="back" type="tertiary" iconleft="clock" onclick="window.productRevisionModal.openModal()"
              >View Revision History</bolt-button
            >
          </bolt-button-bar>
          <bolt-notification type="info"
            >You can only view the details. To make changes, request the owner for edit access in <a [href]="link">IIQ</a></bolt-notification
          >
        </ng-template>

        <div class="container" *ngIf="buttonBar; else viewField" [formGroup]="productForm">
          <h3 class="bolt-heading-sm">Details</h3>
          <div>
            <bolt-textfield
              (blur)="onBlur()"
              inputsize="100"
              class="bolt-space-bottom-lg"
              label="Name"
              maxLength="50"
              required
              [error]="shouldDisplayError(productForm.controls.productName, 'required') ? 'This field is required' : ''"
              ngDefaultControl
              formControlName="productName"
            >
              <bolt-contextual-help slot="help" type="push" heading="Required"> Product Name: Max 50 characters </bolt-contextual-help>
            </bolt-textfield>
          </div>
          <bolt-textfield
            inputsize="125"
            class="bolt-space-bottom-lg"
            label="Subtext"
            maxLength="125"
            ngDefaultControl
            formControlName="subtext"
            value="{{ product.subtext }}"
          >
            <bolt-contextual-help slot="help" type="push" heading="Optional"> Product Subtext: Max 125 characters </bolt-contextual-help>
          </bolt-textfield>
          <div ngDefaultControl formControlName="icon">
            <bolt-select width="fit-content" class="bolt-space-bottom-lg" label="Icon" (change)="fixIcon()" heading="Optional">
              <bolt-contextual-help slot="help" type="push" heading="Optional"> Icon </bolt-contextual-help>
              <option
                *ngFor="let icon of this.icons"
                value="{ &quot;type&quot;: &quot;{{ icon.type }}&quot;, &quot;data&quot;: &quot;{{ icon.data }}&quot; }"
                [selected]="selectedIcon(icon)"
              >
                {{ icon.data }}
              </option>
            </bolt-select>
          </div>
          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col-1">
              <h3 class="bolt-heading-sm" style="margin-right: 20px">Status</h3>
            </div>
            <div class="bolt-col bolt-offset-1">
              <bolt-checkbox-group>
                <div>
                  <bolt-checkbox
                    ngDefaultControl
                    (click)="openStatusModal($event)"
                    (change)="toggleCheckbox(productForm.controls.enabled)"
                    [formControl]="productForm.controls.enabled"
                    [value]="productForm.controls.enabled.value"
                    [checked]="productForm.controls.enabled.value"
                    >{{ productForm.controls.enabled.value ? 'On' : 'Off' }}</bolt-checkbox
                  >
                </div>
              </bolt-checkbox-group>
            </div>
          </div>
          <div>
            <bolt-date-picker
              required
              ngDefaultControl
              formControlName="effectiveDate"
              inputsize="20"
              label="Effective Date"
              min="today"
              max="{{ maxDate }}"
              [error]="productForm.controls.effectiveDate.errors?.['message'] || ''"
              class="bolt-space-bottom-lg"
              pickerposition="right"
              instructionaltext="The date must not be in the past or more than one year in the future"
            >
            </bolt-date-picker>
            <bolt-textfield
              type="number"
              min="0"
              max="100"
              [error]="productForm.controls.rolloutPercentage.errors?.['message'] || ''"
              inputsize="5"
              class="bolt-space-bottom-lg"
              suffix="%"
              label="Rollout %"
              optionaltext="hide"
              aligninput="right"
              required
              ngDefaultControl
              formControlName="rolloutPercentage"
            ></bolt-textfield>
          </div>

          <div class="bolt-space-top-lg">
            <h3 class="bolt-heading-sm">Display Info</h3>
            <div>
              <bolt-checkbox-group
                class="bolt-space-bottom-2xl"
                label="Location"
                [error]="productForm.controls.locationGroup.errors?.['message'] || ''"
                required
              >
                <bolt-checkbox
                  ngDefaultControl
                  (change)="toggleCheckbox(productForm.controls.locationGroup.controls.displayInMobile)"
                  [formControl]="productForm.controls.locationGroup.controls.displayInMobile"
                  class="float-child"
                  name="Mobile"
                  [value]="productForm.controls.locationGroup.controls.displayInMobile.value"
                  [checked]="productForm.controls.locationGroup.controls.displayInMobile.value"
                  >Mobile App</bolt-checkbox
                >
                <bolt-checkbox
                  ngDefaultControl
                  (change)="toggleCheckbox(productForm.controls.locationGroup.controls.displayInWeb)"
                  [formControl]="productForm.controls.locationGroup.controls.displayInWeb"
                  class="float-child"
                  name="Web"
                  [value]="productForm.controls.locationGroup.controls.displayInWeb.value"
                  [checked]="productForm.controls.locationGroup.controls.displayInWeb.value"
                  >Website</bolt-checkbox
                >
              </bolt-checkbox-group>
            </div>

            <div>
              <bolt-select
                ngDefaultControl
                [formControl]="productForm.controls.categoryId"
                (change)="setNewProduct()"
                class="bolt-space-bottom-xl"
                label="Category"
                width="fit-content"
                required
                [error]="shouldDisplayError(productForm.controls.categoryId, 'required') ? 'Please make a selection.' : ''"
              >
                <option *ngFor="let category of categories" [value]="category.id">{{ category.categoryName }}</option>
              </bolt-select>
            </div>

            <div>
              <div *ngIf="products && productForm.controls.categoryId.value && arraySettled">
                <div class="bolt-body-copy-md"><strong>Product Order of Display</strong><br /></div>
                <div cdkDropList class="drop-list" label="Product Order of Display" (cdkDropListDropped)="drop($event, products)">
                  <div class="drop-box" *ngFor="let product of products" cdkDrag>
                    <div class="inner-box">
                      {{ product.productName }}
                      <bolt-icon class="icon" name="menu"></bolt-icon>
                    </div>
                  </div>
                </div>
                <p style="font-size: 12px">Drag to reorder.</p>
              </div>
              <div *ngIf="!arraySettled">Loading products...</div>
            </div>

            <div>
              <div *ngIf="productForm.controls.perks.value && arraySettled && productForm.controls.perks.value.length > 0">
                <div class="bolt-body-copy-md"><strong>Perk Order of Display</strong><br /></div>
                <div cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, product.perks)">
                  <div class="drop-box" *ngFor="let perk of productForm.controls.perks.value" cdkDrag>
                    <div class="inner-box">
                      {{ perk.perkName }}
                      <bolt-icon class="icon" name="menu"></bolt-icon>
                    </div>
                  </div>
                </div>
                <p style="font-size: 12px">Drag to reorder.</p>
              </div>
            </div>

            <div *ngIf="!isCreate" [formGroup]="revisionForm">
              <div class="top-padding">
                <bolt-textarea
                  inputsize="100"
                  class="bolt-space-bottom-lg"
                  label="Revision comments"
                  maxLength="100"
                  required
                  [error]="this.revisionForm.controls.revisionComment.errors?.['message'] || ''"
                  ngDefaultControl
                  formControlName="revisionComment"
                  cols="40"
                ></bolt-textarea>
                <p>Enter between 15 and 100 characters</p>
              </div>
            </div>
          </div>
        </div>

        <ng-template #viewField>
          <h3 class="bolt-heading-sm">Details</h3>
          <div class="bolt-body-copy-lg"><strong>Name</strong><br /></div>
          <p>{{ product.productName }}</p>
          <div class="bolt-body-copy-lg"><strong>Subtext</strong><br /></div>
          <p>{{ product.subtext }}</p>
          <div class="bolt-body-copy-lg"><strong>Icon</strong><br /></div>
          <p>{{ product.icon.data || '' }}</p>
          <hr />
          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col-1">
              <h3 class="bolt-heading-sm" style="margin-right: 20px">Status</h3>
            </div>
            <div class="bolt-col bolt-offset-1">
              <bolt-checkbox-group>
                <div *ngIf="product.enabled; else off">
                  <bolt-checkbox checked="true" disabled>On</bolt-checkbox>
                </div>
                <ng-template #off>
                  <bolt-checkbox disabled>Off</bolt-checkbox>
                </ng-template>
              </bolt-checkbox-group>
            </div>
          </div>
          <div class="bolt-body-copy-lg"><strong>Effective Date</strong><br /></div>
          <p>{{ product.effectiveDate }}</p>
          <div class="bolt-body-copy-lg"><strong>Rollout %</strong><br /></div>
          <p>{{ product.rolloutPercentage }}</p>
          <hr />
          <h3 class="bolt-heading-sm">Display Info</h3>
          <div class="bolt-body-copy-lg"><strong>Location</strong><br /></div>
          <div class="bolt-row">
            <div class="bolt-col-3">
              <p *ngIf="product.displayInMobile">Mobile App</p>
            </div>
            <div class="bolt-col">
              <p *ngIf="product.displayInWeb">Website</p>
            </div>
          </div>
          <div class="bolt-body-copy-lg"><strong>Category</strong><br /></div>
          <p>{{ category?.categoryName }}</p>
          <div class="bolt-body-copy-lg"><strong>Product Order of Display</strong><br /></div>
          <div *ngIf="products">
            <ol>
              <li *ngFor="let product of products" [ngClass]="{ highlighted: product.id === selectedProduct }">
                {{ product.productName }}
              </li>
            </ol>
          </div>
          <div *ngIf="!products?.length">Loading products...</div>
          <hr />
          <div class="bolt-body-copy-lg"><strong>Perk Order of Display</strong><br /></div>
          <div *ngIf="product.perks && product.perks.length > 0">
            <ol>
              <li *ngFor="let perk of product.perks">
                {{ perk.perkName }}
              </li>
            </ol>
          </div>
          <div *ngIf="!product.perks || product.perks.length === 0">None</div>
        </ng-template>
      </div>
      <div *ngIf="!categories">
        <bolt-waiting-indicator>Loading...</bolt-waiting-indicator>
      </div>
    </div>
  </div>

  <!-- Revision Modal -->
  <bolt-modal id="productRevisionModal" type="content" heading="Revision history">
    <app-revision-table [revisions]="(revisions | async) || []"></app-revision-table>
    <bolt-button-bar slot="modal-button-bar">
      <bolt-button type="primary" slot="forward" onclick="window.productRevisionModal.closeModal()">Dismiss</bolt-button>
    </bolt-button-bar>
  </bolt-modal>

  <bolt-modal id="customNavButtonModal" type="question" heading="Are you sure you want to leave?">
    <p>Unsaved changes may be lost.</p>

    <bolt-button-bar slot="modal-button-bar">
      <bolt-button slot="back" (click)="turnOffRouteGuard()" onclick="window.customNavButtonModal.closeModal()">Navigate</bolt-button>
      <bolt-button slot="cancel" (click)="resetModal()" onclick="window.customNavButtonModal.closeModal()">Cancel</bolt-button>
    </bolt-button-bar>
  </bolt-modal>

  <!-- Confirmation Modal -->
  <bolt-modal
    id="questionModal"
    type="question"
    heading="Turn off Product?"
    (bolt-modal-secondary-button-click)="toggleCheckbox(productForm.controls.enabled)"
    (bolt-modal-primary-button-click)="modalCancelToggle()"
    primarybutton="No, keep on"
    secondarybutton="Yes, turn off"
    [open]="this.openModal"
  >
    <p>This product will no longer be shown to users</p>
  </bolt-modal>

  <!-- Request Error Modal -->
  <bolt-modal
    id="requestErrorModal"
    type="error"
    heading="Error"
    primarybutton="Close"
    [open]="openRequestErrorModal"
    (bolt-modal-primary-button-click)="openRequestErrorModal = false"
  >
    <p>There was an issue processing your request.</p>
  </bolt-modal>
</div>
