import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, map, switchMap, throwError } from 'rxjs';
import { v4 } from 'uuid';
import { ConfigService } from '../shared/config.service';
import { Perk } from '../shared/types/perks';
// import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PerkService implements OnDestroy {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
    public oidcSecurityService: OidcSecurityService, // public AuthService: AuthService,
  ) {}

  public clientId = this.config.app.secrets.clientId;
  messageId = () => v4();
  public url = this.config.app.secrets.url;
  public $perks = this.getPerks();
  private subscriptions: Subscription = new Subscription();

  savePerk(perk: Perk, revisionComment: string): Observable<Perk> {
    /*
     * What's going on here and why are we doing a TS ignore?
     *
     * The short answer is working around a Bolt limitation.
     *
     * The longer answer is that bolt selects do not work well
     * with objects so we need to do some mapping to get things
     * to work properly.  This means that when we spread the form
     * object into the perk object, we get a tempFilter property from
     * the form.  Since Typescript doesn't (and realistically doesn't need to)
     * know about we'll ignore the typescript issue and delete the property
     * if it happens to exist.
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (perk.tempFilter !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete perk.tempFilter;
    }

    this.getUserId(perk);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          if (perk.id) {
            perk.revisionComment = revisionComment;
            return this.http.put<Perk>(this.url + `/perks/${perk.id}`, perk, {
              headers: {
                'client_id': this.clientId,
                'X-NW-Message-ID': this.messageId(),
                'Authorization': 'Bearer ' + accessToken,
              },
            });
          } else {
            return this.http.post<Perk>(this.url + '/perks', perk, {
              headers: {
                'client_id': this.clientId,
                'X-NW-Message-ID': this.messageId(),
                'Authorization': 'Bearer ' + accessToken,
              },
            });
          }
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getPerks(): Observable<Perk[]> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http
            .get<Perk[]>(this.url + '/admin/perks', {
              headers: {
                'client_id': this.clientId,
                'X-NW-Message-ID': this.messageId(),
                'Authorization': 'Bearer ' + accessToken,
              },
            })
            .pipe(map((perks) => perks.sort((a, b) => (a.perkName.toUpperCase() > b.perkName.toUpperCase() ? 1 : -1))));
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  deletePerk(perk: Perk): Observable<Perk> {
    this.getUserId(perk);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.delete<Perk>(this.url + `/perks/${perk.id}`, {
            body: perk,
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getUserId(perk: Perk) {
    this.subscriptions.add(
      this.oidcSecurityService.getUserData().subscribe((userData) => {
        perk.userName = userData.displayName;
        perk.userId = userData.userId;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
