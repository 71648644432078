import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Revision } from '../../shared/types/revision';
import { Perk } from '@shared';
import { RevisionTypes, RoutePaths } from '@shared/constants';
import { Location } from '@angular/common';

@Component({
  selector: 'app-revision-table',
  templateUrl: './revision-table.component.html',
  styleUrls: ['./revision-table.component.scss'],
})
export class RevisionTableComponent implements OnChanges {
  @Output() revisionNav = new EventEmitter<any>();
  perkRevision: Revision | undefined;

  @Input() revisions: Revision[] = [];
  @Input() perkRevisions: Revision[] = [];

  filteredRevisions: Revision[] = [];

  dateSort = false;
  editorSort = false;
  commentSort = false;
  searchText = '';
  routerLinks: { [key: string]: string } = {};
  revisionStates: { [key: string]: any } = {};

  constructor(private location: Location) {}

  pathMap: { [key: string]: string } = {
    categories: RoutePaths.RevisionCategory,
    products: RoutePaths.RevisionProduct,
    perks: RoutePaths.RevisionPerk,
    recommendedTiles: RoutePaths.RevisionRecommendedTile,
  };

  getRouterLink(type: string): string {
    return this.pathMap[type] || '/';
  }
  navigateToRevision() {
    this.revisionNav.emit();
  }

  cacheRouterLinks() {
    this.filteredRevisions.forEach((filteredRevision) => {
      this.routerLinks[filteredRevision.type] = this.getRouterLink(filteredRevision.type);
    });
  }
  setRevisionStates() {
    this.revisionStates = {};
    this.filteredRevisions.forEach((filteredRevision) => {
      if (filteredRevision.type === 'recommendedTile' && filteredRevision.updatedObject) {
        this.revisionStates[filteredRevision.id] = { updatedObject: filteredRevision.updatedObject, perkObject: filteredRevision.perkObject };
      } else if (filteredRevision.updatedObject) {
        this.revisionStates[filteredRevision.id] = filteredRevision.updatedObject;
      } else {
        this.revisionStates[filteredRevision.id] = filteredRevision.originalObject;
      }
    });
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.filteredRevisions = this.revisions;
    this.cacheRouterLinks();
    this.setRevisionStates();
  }
  sortDate() {
    const value = this.dateSort;
    if (value === false) {
      this.revisions = this.revisions.sort((a, b) => a.lastUpdated - b.lastUpdated);
      this.dateSort = true;
    } else {
      this.revisions = this.revisions.sort((a, b) => b.lastUpdated - a.lastUpdated);
      this.dateSort = false;
    }
  }

  sortEditor() {
    const value = this.editorSort;
    if (value === false) {
      this.revisions = this.revisions.sort((a, b) => (a.userName.toUpperCase() > b.userName.toUpperCase() ? 1 : -1));
      this.editorSort = true;
    } else {
      this.revisions = this.revisions.sort((a, b) => (a.userName.toUpperCase() < b.userName.toUpperCase() ? 1 : -1));
      this.editorSort = false;
    }
  }

  sortComment() {
    const value = this.commentSort;
    if (value === false) {
      this.revisions = this.revisions.sort((a, b) => (a.revisionComment.trim().toUpperCase() > b.revisionComment.trim().toUpperCase() ? 1 : -1));
      this.commentSort = true;
    } else {
      this.revisions = this.revisions.sort((a, b) => (a.revisionComment.trim().toUpperCase() < b.revisionComment.trim().toUpperCase() ? 1 : -1));
      this.commentSort = false;
    }
  }

  filterRevisionsBySearchText() {
    if (this.searchText === '') {
      this.filteredRevisions = this.revisions;
    }
    this.filteredRevisions = this.revisions.filter((revision) => {
      return (
        revision.revisionComment.toLowerCase().includes(this.searchText.toLowerCase()) ||
        revision.userName.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }
}
