import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RevisionService } from '@services/revision.service';
import { Category } from '../../shared/types/categories';
import { Perk } from '../../shared/types/perks';
import { Product } from '../../shared/types/products';
import { RecommendedTile } from '../../shared/types/recommendedTile';
import { Revision } from '../../shared/types/revision';

@Component({
  selector: 'app-button-bar',
  templateUrl: './button-bar.component.html',
  styleUrls: ['./button-bar.component.scss'],
})
export class ButtonBarComponent implements OnInit, OnChanges {
  @Input() buttonBar = false;
  @Input() revisionBar = false;
  @Input() category: Category = {} as Category;
  @Input() product: Product = {} as Product;
  @Input() perk: Perk = {} as Perk;
  @Input() recommendedTile: RecommendedTile = {} as RecommendedTile;
  @Output() saved = new EventEmitter<boolean>();
  @Output() edit = new EventEmitter<{ active: boolean; object: Category | Product | Perk | RecommendedTile }>();
  @Output() delete = new EventEmitter<boolean>();
  @Output() revisionNav = new EventEmitter<{
    perkObject: Perk;
  }>();

  revisions: Observable<Revision[]> = of([]);
  perkRevisions: Observable<Revision[]> = of([]);

  editView = false;
  type = '';
  dateSort = false;
  editorSort = false;
  commentSort = false;
  path = '';
  navigationId: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private revisionService: RevisionService,
    private location: Location,
  ) {
    this.buttonBar = !!this.router.url.match('^/[^/]+/create$');
    this.editView = !!this.router.url.match('^/[^/]+/edit');
  }
  handleEdit() {
    const object: any = {};
    this.setNavObjectAndValues(object);
    const queryParams: any = { id: object.navObject.id };
    const path = `/${object.path}/edit`;

    this.router.navigate([path], {
      queryParams: queryParams,
      state: object.navObject,
    });
  }

  goBack() {
    this.location.back();
  }

  navigateToRevision() {
    (window as any).buttonBarRevisionModal?.closeModal();
  }
  ngOnInit() {
    const object: any = {};
    this.setNavObjectAndValues(object);
  }

  ngOnChanges() {
    const object: any = {};
    this.setNavObjectAndValues(object);
  }

  saveFunction(activated: boolean) {
    this.saved.emit(activated);
  }

  deleteFunction(activated: boolean) {
    this.delete.emit(activated);
  }

  setNavObjectAndValues(object: any): any {
    const currentUrl = this.router.url;

    if (currentUrl.match('^/category/*')) {
      object.navObject = this.category;
      object.path = 'category';
      this.type = 'category';
      if (this.category.id) this.revisions = this.revisionService.getRevisions('categories', this.category.id);
    } else if (currentUrl.match('^/product/*')) {
      object.navObject = this.product;
      object.path = 'product';
      this.type = 'product';
      if (this.product.id) this.revisions = this.revisionService.getRevisions('products', this.product.id);
    } else if (currentUrl.match('^/recommended/*')) {
      object.navObject = this.recommendedTile;
      object.path = 'recommended';
      this.type = 'recommendedTiles';
      if (this.recommendedTile.id) this.revisions = this.revisionService.getRevisions('recommendedTiles', this.recommendedTile.id);
      this.perkRevisions = this.revisionService.getRevisions('perks', this.recommendedTile.perkId);
    } else if (currentUrl.match('^/perk/*')) {
      object.navObject = this.perk;
      object.path = 'perk';
      this.type = 'perk';
      if (this.perk.id) this.revisions = this.revisionService.getRevisions('perks', this.perk.id);
    } else {
      object.navObject = null;
      object.path = '';
      this.type = '';
      this.revisions = of([]);
      this.perkRevisions = of([]);
    }
  }
}
