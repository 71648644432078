import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, DefaultUrlSerializer, Router } from '@angular/router';
import { CategoryService, PerkService, ProductService, RevisionService } from '@services';
import {
  BenefitType,
  Category,
  HOME_AGE_VALUES,
  HomeAge,
  ICONS,
  IIQ_LINK,
  POLICIES_REQUIRED,
  Perk,
  Product,
  RATED_STATES,
  RATED_STATES_SAVE,
  RecommendedTile,
  Revision,
  RoutePaths,
  Rule,
  TENURE_VALUES,
  defaultMaxDate,
  effectiveDateValidator,
  locationValidator,
  requiredIfFactory,
  revisionValidator,
  rolloutPercentageValidator,
  shouldDisplayError,
  urlValidatorFactory,
} from '@shared';
import { Observable, Subscription, firstValueFrom, of } from 'rxjs';

type SortedProducts = {
  [key: string]: Product[];
};

@Component({
  selector: 'app-perk',
  templateUrl: './perk.component.html',
  styleUrls: ['./perk.component.scss'],

  encapsulation: ViewEncapsulation.None,
})
export class PerkComponent implements OnInit, OnDestroy {
  urlSerializer = new DefaultUrlSerializer();
  products: Product[] | undefined = undefined;
  perks: Perk[] = [] as Perk[];
  buttonBar = true;
  revisionBar = false;
  isCreate = false;
  readOnly = true;
  isEdit = false;
  deletedState = false;
  disableAll = false;
  openModal = false;
  openRequestErrorModal = false;
  link = IIQ_LINK;
  BenefitType = BenefitType;
  policiesRequired = POLICIES_REQUIRED;
  ratedStates = RATED_STATES;
  statesSave = RATED_STATES_SAVE;
  tenureArray = TENURE_VALUES;
  homeAgeOptions: HomeAge[] = HOME_AGE_VALUES;
  displayHomeAge = false;
  perk: Perk = {} as Perk;
  category: Category = {} as Category;
  sortedProducts: SortedProducts = {};

  matchStates: string[] = [];
  categories: Category[] | undefined = undefined;
  rules: Rule[] = [] as Rule[];
  savedStates: string[] = [];
  currentIndex = 0;
  numRule = 1;
  element = '';
  newState = '';
  currentName = '';
  currentAnalytics = '';
  product: Product = {} as Product;
  selectedPerk = '';
  icons = ICONS;
  revisions: Observable<Revision[]> = of([]);
  maxDate: string | null = defaultMaxDate;
  shouldDisplayError = shouldDisplayError;
  private subscriptions: Subscription = new Subscription();
  hasUnsavedChanges = false;
  navObject: { object: Category | Product | Perk | RecommendedTile } = { object: {} as Category | Product | Perk | RecommendedTile };
  navPath = '';
  filteredCategories: Category[] | undefined = undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private perkService: PerkService,
    private productService: ProductService,
    private location: Location,
    private categoryService: CategoryService,
    private fb: FormBuilder,
    private revisionService: RevisionService,
  ) {
    const createCheck = this.router.url.match(RoutePaths.CreatePerk) != null;
    this.buttonBar = createCheck;
    this.isCreate = createCheck;
  }

  perkForm = this.fb.group({
    perkName: new FormControl(''),
    analyticsName: new FormControl(''),
    description: new FormControl('', requiredIfFactory(this.descriptionRequiredCondition.bind(this), 'This field is required if no URL is entered')),
    type: new FormControl(''),
    icon: new FormControl<{ type: string; data: string } | null>(null, Validators.required),
    mobileUrl: new FormControl('', [
      requiredIfFactory(this.mobileUrlRequiredCondition.bind(this), 'This field is required if the ECN box is checked'),
      urlValidatorFactory(this.ecnCheckedCondition.bind(this), 'This field must be a nationwide.com URL if the ECN box is checked'),
    ]),
    webUrl: new FormControl('', [
      requiredIfFactory(this.webUrlRequiredCondition.bind(this), 'This field is required if the ECN box is checked'),
      urlValidatorFactory(this.ecnCheckedCondition.bind(this), 'This field must be a nationwide.com URL if the ECN box is checked'),
    ]),
    ecnGroup: new FormGroup({
      ecnEnabled: new FormControl(false),
    }),
    enabled: new FormControl(false),
    effectiveDate: new FormControl('', effectiveDateValidator),
    rolloutPercentage: new FormControl<number | string>('', rolloutPercentageValidator),
    rules: new FormArray([this.buildRule()]),
    locationGroup: new FormGroup(
      {
        displayInMobile: new FormControl(false),
        displayInWeb: new FormControl(false),
      },
      locationValidator,
    ),
  });

  descriptionRequiredCondition(): boolean | null {
    return (
      (this.perkForm.controls.locationGroup.controls.displayInMobile.value && !this.perkForm.controls.mobileUrl.value) ||
      (this.perkForm.controls.locationGroup.controls.displayInWeb.value && !this.perkForm.controls.webUrl.value) ||
      false
    );
  }

  mobileUrlRequiredCondition(): boolean | null {
    return this.perkForm.controls.locationGroup.controls.displayInMobile.value && this.perkForm.controls.ecnGroup.controls.ecnEnabled.value;
  }

  webUrlRequiredCondition(): boolean | null {
    return this.perkForm.controls.locationGroup.controls.displayInWeb.value && this.perkForm.controls.ecnGroup.controls.ecnEnabled.value;
  }

  ecnCheckedCondition(): boolean | null {
    return this.perkForm.controls.ecnGroup.controls.ecnEnabled.value;
  }

  ecnChanged() {
    this.toggleCheckbox(this.perkForm.controls.ecnGroup.controls.ecnEnabled);
    [this.perkForm.controls.mobileUrl, this.perkForm.controls.webUrl].forEach(this.validateControl);
  }

  toggleCheckbox(control: FormControl) {
    control.patchValue(!control.value);
    control.markAsTouched();
    this.openModal = false;
  }

  modalCancelToggle() {
    this.perkForm.controls.enabled.patchValue(true);
    this.openModal = false;
  }

  openStatusModal(event: MouseEvent) {
    if (this.perkForm.controls['enabled'].value) {
      event.preventDefault();
      this.openModal = true;
    }
  }

  validateControl(control: AbstractControl) {
    control.markAsDirty();
    control.markAllAsTouched();
    control.updateValueAndValidity();
  }

  buildRule() {
    return new FormGroup({
      categoryId: new FormControl('', Validators.required),
      productId: new FormControl('', Validators.required),
      requiredPolicies: new FormControl<{ [key: string]: boolean }[]>([], Validators.required),
      isNA: new FormControl(false),
      tenure: new FormControl<{ comparison: string; value: number; display: string } | null>(null, Validators.required),
      homeAge: new FormControl<HomeAge | null>(null),
      ratedState: new FormControl<string[]>([], Validators.required),
    });
  }

  addRule() {
    this.numRule += 1;
    const rulesForm = this.buildRule();
    this.perkForm.controls.rules.push(rulesForm);
    this.disableAll = false;
  }

  deleteRules(ruleIndex: number) {
    this.numRule -= 1;
    this.perkForm.controls.rules.removeAt(ruleIndex);
  }

  fixTenure(index: number) {
    if (this.perkForm.value.rules?.[index].tenure) {
      this.perkForm.controls.rules.controls[index].controls.tenure.patchValue(JSON.parse(String(this.perkForm.value.rules[index].tenure || 'null')));
    }
  }

  fixIcon() {
    if (this.perkForm.value.icon) {
      this.perkForm.controls.icon.patchValue(JSON.parse(String(this.perkForm.value.icon)));
    }
  }

  selectedIcon(icon: any) {
    try {
      return this.perk.icon.data === icon.data && this.perk.icon.type === icon.type;
    } catch (error) {
      return false;
    }
  }

  selectedHomeAge(homeAgeOption: any, index: number) {
    try {
      return (
        this.perk.rules[index].homeAge?.lowerValue === homeAgeOption.lowerValue &&
        this.perk.rules[index].homeAge?.upperValue === homeAgeOption.upperValue &&
        this.perk.rules[index].homeAge?.title === homeAgeOption.title
      );
    } catch (error) {
      return false;
    }
  }

  printKey(element: KeyboardEvent, index: number) {
    this.matchStates = [];
    let keyPress = element.key;
    if (this.element.length === 0) {
      keyPress = keyPress.toUpperCase();
    } else if (this.element.endsWith(' ')) {
      keyPress = keyPress.toUpperCase();
    } else {
      keyPress = keyPress.toLowerCase();
    }
    if (element.key === 'Enter') {
      this.addRatedState(index);
    } else {
      this.element = this.element.concat('', keyPress);
      this.ratedStates.filter((item) => item.match(this.element)).forEach((item) => this.matchStates.push(item));
    }
  }

  otherKey(element: KeyboardEvent) {
    if (element.key === 'Backspace') {
      this.matchStates = [];
      this.element = this.element.substring(0, this.element.length - 1);
      this.ratedStates.filter((item) => item.match(this.element)).forEach((item) => this.matchStates.push(item));
    }
  }

  announcer = inject(LiveAnnouncer);

  remove(state: string, num: number): void {
    const index = this.perkForm.value.rules?.[num].ratedState?.indexOf(state);

    if (index !== undefined && index >= 0) {
      this.perkForm.value.rules?.[num].ratedState?.splice(index, 1);
      this.validateControl(this.perkForm.controls.rules?.controls[num].controls.ratedState);
      this.announcer.announce(`Removed ${state}`);
      this.deletedState = true;
    }
  }

  revisionForm = new FormGroup({
    revisionComment: new FormControl('', revisionValidator),
  });

  displayInMobileEnabled() {
    if (this.perkForm.value.webUrl && this.perkForm.controls.locationGroup.value.displayInMobile) {
      this.perkForm.controls.mobileUrl.patchValue(this.perkForm.value.webUrl);
    } else {
      this.perkForm.controls.mobileUrl.patchValue('');
    }
  }

  displayInWebEnabled() {
    if (this.perkForm.value.mobileUrl && this.perkForm.controls.locationGroup.value.displayInWeb) {
      this.perkForm.controls.webUrl.patchValue(this.perkForm.value.mobileUrl);
    } else {
      this.perkForm.controls.webUrl.patchValue('');
    }
  }

  async edit(editObject: { active: boolean; object: Category | Product | Perk | RecommendedTile }) {
    const perk = this.perk ? this.perk : (editObject.object as Perk);
    if (editObject.active) {
      this.router.navigateByUrl(RoutePaths.EditPerk, { state: perk });
    }
  }

  async nav(navObject: { object: Category | Product | Perk | RecommendedTile }) {
    if ((this.router.url.match(`^${RoutePaths.EditPerk}`) || this.isCreate) && this.perkForm.touched) {
      this.hasUnsavedChanges = true;
      (window as any).customNavButtonModal?.openModal();
      this.navObject.object = navObject.object;
    } else {
      this.hasUnsavedChanges = false;
      this.perkService.$perks.subscribe((res) => {
        this.perks = res;
      });
      if (this.perk.id && this.readOnly) this.revisions = this.revisionService.getRevisions('perks', this.perk.id);
      this.highlightPerkCheck();
    }
    this.revisionBar = false;
  }

  stateSaveAbbreviation() {
    let finalStates: string[] = [];
    for (let i = 0; i < (this.perkForm.value.rules?.length || 0); i++) {
      this.perkForm.value.rules?.[i].ratedState?.forEach((state: string) =>
        this.statesSave.forEach((stateObject) => {
          if (stateObject.name === state) {
            finalStates.push(stateObject.abbreviation);
          }
        }),
      );
      this.perk.rules[i].ratedState = finalStates;
      finalStates = [];
    }
  }

  stateEditAbbreviation() {
    let finalStates: string[] = [];
    for (let i = 0; i < this.perk.rules.length; i++) {
      this.perk.rules[i].ratedState.forEach((state: any) =>
        this.statesSave.forEach((stateObject) => {
          if (stateObject.abbreviation === state) {
            finalStates.push(stateObject.name);
          }
        }),
      );
      this.perk.rules[i].ratedState = finalStates;
      finalStates = [];
    }
  }

  async save(activated: boolean) {
    let originalPerk: Perk;
    const { locationGroup, ecnGroup, ...perkForm } = this.perkForm.value;
    const formPerk = {
      ...this.perk,
      ...perkForm,
      ...locationGroup,
      ...ecnGroup,
    };
    if (this.isEdit) {
      originalPerk = this.perk;
    }
    this.perk = formPerk as Perk;
    [
      ...Object.values(this.perkForm.controls),
      ...this.perkForm.controls.rules.controls.map((f) => Object.values(f.controls)).flat(1),
      ...Object.values(this.revisionForm.controls),
    ].forEach(this.validateControl);
    if (activated) {
      if (this.perkForm.valid && (this.revisionForm.valid || this.isCreate) && this.products) {
        this.stateSaveAbbreviation();
        const revisionComment = this.revisionForm.value.revisionComment as string;

        this.perk.rules.forEach((rule) => {
          if (typeof rule.homeAge === 'string') {
            rule.homeAge = JSON.parse(JSON.parse(JSON.stringify(rule.homeAge)));
          }
        });

        (window as any).requestIndicator.show();
        this.subscriptions.add(
          this.perkService.savePerk(this.perk, revisionComment).subscribe({
            next: async (perk) => {
              this.perk = perk;
              await this.reorderPerks(false, originalPerk);
              this.buttonBar = false;
              this.hasUnsavedChanges = false;
              this.highlightPerkCheck();
              (window as any).requestIndicator.hide();
              this.navigateTo('perk', this.perk);
            },
            error: () => {
              (window as any).requestIndicator.hide();
              this.openRequestErrorModal = true;
            },
          }),
        );
      }
    }
  }

  async delete(activated: boolean) {
    if (activated) {
      (window as any).requestIndicator.show();
      this.subscriptions.add(
        this.perkService.deletePerk(this.perk).subscribe({
          next: () => {
            (window as any).requestIndicator.hide();
            this.router.navigateByUrl(`/home`);
          },
          error: () => {
            (window as any).requestIndicator.hide();
            this.openRequestErrorModal = true;
          },
        }),
      );
      await this.reorderPerks(true);
    }
  }
  navigateTo(path: string, object: any) {
    this.router.navigate([`/${path}`], {
      queryParams: { id: object.id },
      state: object,
    });
  }

  selectedTenure(index: number, tenure: any) {
    try {
      return (
        this.perkForm.value.rules?.[index].tenure?.value === tenure.value &&
        this.perkForm.value.rules?.[index].tenure?.comparison === tenure.comparison
      );
    } catch (error) {
      return false;
    }
  }

  getState(index: number): string[] {
    return this.perkForm.value.rules?.[index].ratedState || [];
  }

  getIndex(index: number) {
    this.currentIndex = index;
  }

  async reorderPerks(isDelete: boolean, originalPerk: Perk | undefined = undefined) {
    const rules: Rule[] = this.isCreate ? (this.perkForm.get('rules')?.value as Rule[]) : this.perk.rules;
    const productArray = this.products ?? [];

    if (originalPerk && rules && rules.length > 0) {
      const rulesWithOldProduct = originalPerk.rules.filter((rule) => {
        const newRule = rules.find((newRule) => newRule.productId === rule.productId);
        return !newRule;
      });

      for (const rule of rulesWithOldProduct) {
        const product = productArray.find((product) => product.id === rule.productId);
        if (product) {
          product.perks = product.perks.filter((perk) => perk.id !== originalPerk.id);
          await firstValueFrom(this.productService.saveProduct(product, 'Changed display order'));
        }
      }
    }

    if (rules && productArray.length > 0) {
      const products = productArray.filter((product) => rules.some((rule) => product.id === rule.productId));
      for (const product of products) {
        if (isDelete) {
          product.perks = product.perks.filter((perk) => perk.id !== this.perk.id);
        } else {
          const perkIndex = product.perks.findIndex((perk) => perk.id === this.perk.id);
          if (perkIndex === -1) {
            product.perks.push(this.perk);
          } else {
            product.perks[perkIndex] = this.perk;
          }
        }
        await firstValueFrom(this.productService.saveProduct(product, 'Changed display order'));
      }
    }
  }

  filterProducts() {
    const sortedProducts: SortedProducts = {};

    if (!this.products || !this.categories) {
      // one of the 2 API calls haven't returned yet.  Do nothing.
      return;
    }
    this.filteredCategories = [];
    this.categories?.forEach((category) => {
      const products = this.products?.filter((product) => product.categoryId === category.id);

      if (products && products.length > 0 && this.filteredCategories) {
        sortedProducts[category.id] = products;
        this.filteredCategories.push(category);
      }
    });
    this.sortedProducts = sortedProducts;
  }

  ngOnInit() {
    Promise.all([
      firstValueFrom(this.perkService.$perks),
      firstValueFrom(this.productService.$products),
      firstValueFrom(this.categoryService.$categories),
    ]).then(([perks, products, categories]) => {
      this.perks = perks;

      this.products = products.sort((a, b) => a.orderOfDisplay - b.orderOfDisplay);

      this.categories = categories;
      this.categories.sort((a, b) => (a.categoryName.toUpperCase() > b.categoryName.toUpperCase() ? 1 : -1));
      this.filterProducts();

      this.readOnly = sessionStorage.getItem('readOnly') === 'false' ? false : true;
      this.perk = this.location.getState() as Perk;
      this.highlightPerkCheck();
      if (this.perk.id && this.readOnly) this.revisions = this.revisionService.getRevisions('perks', this.perk.id);
      this.revisionCheck();
      this.editCheck();

      this.subscriptions.add(
        this.route.queryParams.subscribe(() => {
          const navigation = this.router.getCurrentNavigation();
          if (navigation && navigation.extras.state) {
            this.perk = navigation.extras.state as Perk;
          }
        }),
      );

      if (this.isCreate && this.perkForm.value.rules?.length === 0) {
        this.addRule();
      }
    });
  }
  revisionCheck() {
    if (this.perk != null && this.router.url.match('/perk/revision')) {
      this.revisionBar = true;
    }
  }

  editCheck() {
    if (this.perk != null && this.router.url.match(`^${RoutePaths.EditPerk}`)) {
      this.numRule = 0;
      this.isEdit = true;
      this.stateEditAbbreviation();
      this.buttonBar = true;
      this.hasUnsavedChanges = false;

      setTimeout(() => {
        this.perkForm.controls.rules.clear();
        this.perk.rules.forEach((rule) => {
          rule.homeAge = Object.keys(rule.homeAge ?? {}).length === 0 ? undefined : rule.homeAge;
          this.addRule();
        });
        this.perkForm.patchValue({
          ...this.perkForm.value,
          ...this.perk,
          locationGroup: {
            displayInMobile: this.perk.displayInMobile || false,
            displayInWeb: this.perk.displayInWeb || false,
          },
          ecnGroup: {
            ecnEnabled: this.perk.ecnEnabled || false,
          },
        });
      }, 700);
    } else {
      // prevent checkbox toggling animation on edit page load
      // by defaulting to false and then setting to true if not edit mode
      setTimeout(() => this.perkForm.controls.enabled.setValue(true));
    }
  }

  setSelectedPolicyValue(policy: any, index: number, value: boolean) {
    if (policy != 'N/A') {
      const policyObject = { [policy.name]: value };
      const position =
        this.perkForm.value.rules?.[index].requiredPolicies?.map((currentPolicy) => Object.keys(currentPolicy)[0]).indexOf(policy.name) ?? -1;
      const valuePosition = this.perkForm.value.rules?.[index].requiredPolicies?.some(
        (item: any) => JSON.stringify(item) === JSON.stringify(policyObject),
      );
      this.displayHomeAge = policy.name === 'Home' && (position === -1 || !valuePosition) ? value : false;
      if (position === -1) {
        this.perkForm.value.rules?.[index].requiredPolicies?.push(policyObject);
        this.validateControl(this.perkForm.controls.rules?.controls[index]?.controls.requiredPolicies);
      } else if (position != -1 && valuePosition) {
        this.perkForm.value.rules?.[index].requiredPolicies?.splice(position, 1);
        this.validateControl(this.perkForm.controls.rules?.controls[index]?.controls.requiredPolicies);
      } else {
        if (this.perkForm.value.rules?.[index].requiredPolicies?.[position]) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.perkForm.value.rules[index].requiredPolicies![position] = policyObject;
          this.validateControl(this.perkForm.controls.rules?.controls[index]?.controls.requiredPolicies);
        }
      }
    }
  }

  naDisabled(index: number) {
    const notAvailableObject = { 'N/A': true };
    const notAvailableValue = this.perkForm.value.rules?.[index].requiredPolicies?.some((item) => {
      return JSON.stringify(item) === JSON.stringify(notAvailableObject);
    });
    if (notAvailableValue) {
      this.perkForm.controls.rules?.controls[index]?.controls.requiredPolicies?.value?.splice(0, 1);
      this.validateControl(this.perkForm.controls.rules?.controls[index]?.controls.requiredPolicies);
      this.perkForm.controls.rules?.controls[index].controls.isNA?.patchValue(false);
    } else {
      this.displayHomeAge = false;
      const length = this.perkForm.value.rules?.[index].requiredPolicies?.length;
      this.perkForm.value.rules?.[index].requiredPolicies?.splice(0, length);
      this.perkForm.value.rules?.[index].requiredPolicies?.push(notAvailableObject);
      this.perkForm.controls.rules?.controls[index].controls.homeAge.patchValue(null);
      this.validateControl(this.perkForm.controls.rules?.controls[index]?.controls.requiredPolicies);
      this.perkForm.controls.rules?.controls[index].controls.isNA.patchValue(true);
    }
  }

  nameClick() {
    this.currentName = this.perkForm.value.perkName ?? '';
  }
  analyticsClick() {
    this.currentAnalytics = this.perkForm.value.analyticsName ?? '';
  }

  addRatedState(index: number) {
    const lowerState = this.newState.toLowerCase();
    const stateVal = this.ratedStates.find((arrayState: string) => {
      return lowerState === arrayState.toLowerCase();
    });
    if (lowerState === 'all states') {
      if (this.perkForm.value.rules?.[index].ratedState?.length) {
        this.perkForm.controls.rules.controls[index].controls.ratedState.patchValue([]);
      }
      this.ratedStates.forEach((state) => {
        if (state != 'All States') {
          this.perkForm.value.rules?.[index].ratedState?.push(state);
        }
      });
      this.validateControl(this.perkForm.controls.rules?.controls[index].controls.ratedState);
      this.matchStates = [];
      this.newState = '';
      this.element = '';
    } else if (
      stateVal &&
      !this.perkForm.value.rules?.[index].ratedState?.some((arrayState: string) => {
        return lowerState === arrayState.toLowerCase();
      })
    ) {
      this.perkForm.value.rules?.[index].ratedState?.push(stateVal);
      this.validateControl(this.perkForm.controls.rules?.controls[index].controls.ratedState);
      this.matchStates = [];
      this.newState = '';
      this.element = '';
    }
  }

  onBlur() {
    if ((this.currentName != this.perkForm.value.perkName || this.currentAnalytics != this.perkForm.value.analyticsName) && !this.isEdit) {
      this.setAnalyticsName();
    }
  }

  setAnalyticsName() {
    let editName = this.perkForm.value.perkName;
    if (this.currentName != this.perkForm.value.perkName) {
      editName = this.removeSpecialChar(editName ?? '');
      this.perkForm.get('analyticsName')?.patchValue(editName);
      this.currentName = this.perkForm.value.perkName ?? '';
      this.currentAnalytics = this.perkForm.value.analyticsName ?? '';
    } else {
      let analyticsText = this.perkForm.value.analyticsName;
      analyticsText = this.removeSpecialChar(analyticsText ?? '');
      this.perkForm.get('analyticsName')?.patchValue(analyticsText);
      this.currentAnalytics = this.perkForm.value.analyticsName ?? '';
    }
  }

  removeSpecialChar(name: string) {
    name = name?.toLowerCase();
    name = name?.replace(/google/g, '');
    name = name?.replace(/[ _]ga[ _]/g, '_');
    name = name?.replace(/\bga\b/g, '');
    name = name?.replace(/\^*[ _]ga$/g, '');
    name = name?.replace(/[^a-zA-Z0-9_ ]/g, '');
    const nameArray = name?.toLowerCase().trim().split('');
    nameArray?.forEach((char, index) => {
      if (char === ' ') {
        nameArray.splice(index, 1, '_');
      }
    });
    name = nameArray.join('');
    return name;
  }

  checkedPolicies(policy: any, index: any, value: boolean) {
    let check = false;
    this.perkForm.value.rules?.[index].requiredPolicies?.forEach((current: any) => {
      const currentValue = current[Object.keys(current)[0]];
      if (policy.name === Object.keys(current)[0] && value === currentValue) {
        check = true;
      }
    });
    return check;
  }

  highlightPerkCheck() {
    this.selectedPerk = this.perk.id;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.perkForm.dirty ? this.confirmModal() : (this.hasUnsavedChanges = false);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: any) {
    if (this.perkForm.dirty) {
      event.preventDefault();
    }
  }

  confirmModal() {
    const userChoice = confirm('Are you sure you want to leave? Changes made may not be saved.');
    userChoice ? (this.hasUnsavedChanges = false) : (this.hasUnsavedChanges = true);
  }

  urlPath(path: string) {
    this.navPath = path;
  }
  turnOffRouteGuard() {
    this.hasUnsavedChanges = false;
    this.navigateTo(this.navPath, this.navObject.object);
  }

  resetModal() {
    (window as any).customNavButtonModal?.closeModal();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
