import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, map, switchMap, throwError } from 'rxjs';
import { v4 } from 'uuid';
import { ConfigService } from '../shared/config.service';
import { Product } from '../shared/types/products';

@Injectable({
  providedIn: 'root',
})
export class ProductService implements OnDestroy {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  clientId = this.config.app.secrets.clientId;
  messageId = () => v4();
  public url = this.config.app.secrets.url;
  public $products = this.getProducts();
  private subscriptions: Subscription = new Subscription();

  saveProduct(product: Product, revisionComment: string): Observable<Product> {
    this.getUserId(product);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          if (product.id) {
            product.revisionComment = revisionComment;
            return this.http.put<Product>(this.url + `/products/${product.id}`, product, {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            });
          } else {
            return this.http.post<Product>(this.url + '/products', product, {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            });
          }
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getProducts(): Observable<Product[]> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http
            .get<Product[]>(this.url + '/admin/products', {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            })
            .pipe(map((products) => products.sort((a, b) => (a.productName.toUpperCase() > b.productName.toUpperCase() ? 1 : -1))));
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  deleteProduct(product: Product): Observable<Product> {
    this.getUserId(product);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.delete<Product>(this.url + `/products/${product.id}`, {
            body: product,
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getUserId(product: Product) {
    this.subscriptions.add(
      this.oidcSecurityService.getUserData().subscribe((userData) => {
        product.userName = userData.displayName;
        product.userId = userData.userId;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
