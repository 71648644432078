<bolt-accordion-group>
  <bolt-accordion *ngIf="!readOnly" header="+ Create New" class="bolt-background-vibrant-blue" #accordion>
    <div>
      <div style="display: flex; align-items: baseline">
        <bolt-button
          [disabled]="this.categoryDisabled"
          [ngStyle]="{ width: categoryDisabled ? '40%' : '100%' }"
          type="primary"
          href="/category/create"
          #button
        >
          <div class="bolt-body-copy-lg">Category</div>
        </bolt-button>
        <bolt-contextual-help *ngIf="this.categoryDisabled" slot="help" type="push" heading="Required">
          Remove an enabled Category with Mobile Section to create new Category.
        </bolt-contextual-help>
      </div>

      <bolt-button width="full" type="primary" href="/product/create" #button><div class="bolt-body-copy-lg">Product</div></bolt-button>
      <bolt-button width="full" type="primary" href="/perk/create" #button><div class="bolt-body-copy-lg">Perks & Rewards</div></bolt-button>
      <bolt-button width="full" type="primary" href="/recommended/create" #button
        ><div class="bolt-body-copy-lg">Recommended for you</div></bolt-button
      >
    </div>
  </bolt-accordion>

  <div *ngIf="categories && products && perks && recommendedTiles">
    <bolt-accordion class="accordion-width" header="Category" #accordion>
      <div *ngFor="let category of categories">
        <a
          routerLink="/category"
          [queryParams]="{ id: category.id }"
          [state]="category"
          class="indented-link"
          (click)="onLinkClick('category', category)"
          >{{ category.categoryName }}</a
        >
      </div>
    </bolt-accordion>
    <bolt-accordion class="accordion-width" header="Product" #accordion>
      <div *ngFor="let product of products">
        <a
          routerLink="/product"
          [queryParams]="{ id: product.id }"
          [state]="product"
          class="indented-link"
          (click)="onLinkClick('product', product)"
          >{{ product.productName }}</a
        >
      </div>
    </bolt-accordion>
    <bolt-accordion class="accordion-width" header="Perks & Rewards" #accordion>
      <div *ngFor="let perk of perks">
        <a routerLink="/perk" [queryParams]="{ id: perk.id }" [state]="perk" class="indented-link" (click)="onLinkClick('perk', perk)">{{
          perk.perkName
        }}</a>
      </div>
    </bolt-accordion>
    <bolt-accordion class="accordion-width" header="Recommended for you" #accordion>
      <div *ngFor="let recommendedTile of recommendedTiles">
        <a
          routerLink="/recommended"
          [queryParams]="{ id: recommendedTile.id }"
          [state]="recommendedTile"
          class="indented-link"
          (click)="onLinkClick('recommended', recommendedTile)"
          >{{ recommendedTile.recommendedTileName }}</a
        >
      </div>
    </bolt-accordion>
  </div>
  <div *ngIf="!categories || !products || !perks || !recommendedTiles" class="top-padding text-center"><em>Loading data...</em></div>
</bolt-accordion-group>
