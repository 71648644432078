<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<bolt-textfield
  inputsize="100"
  class="bolt-space-bottom-lg"
  label="Search"
  maxLength="50"
  ngDefaultControl
  [(ngModel)]="searchText"
  (ngModelChange)="filterRevisionsBySearchText()"
>
  <bolt-contextual-help slot="help" type="push" heading="Search">Filter revisions by Editor or Comments</bolt-contextual-help>
</bolt-textfield>
<div class="bolt-table bolt-table-striped bolt-table-dark-blue">
  <table>
    <thead>
      <tr>
        <th id="date" scope="col">
          <div class="headers">Date <bolt-icon class="icon" name="sort-unsorted" (click)="sortDate()"></bolt-icon></div>
        </th>
        <th id="editor" scope="col">
          <div class="headers">Editor <bolt-icon class="icon" name="sort-unsorted" (click)="sortEditor()"></bolt-icon></div>
        </th>
        <th id="comments" scope="col">
          <div class="headers">Comments <bolt-icon class="icon" name="sort-unsorted" (click)="sortComment()"></bolt-icon></div>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="!filteredRevisions">
      <em>Loading Revisions...</em>
    </tbody>
    <tbody>
      <tr *ngFor="let revision of filteredRevisions">
        <td>{{ revision.created | date: 'shortDate' }}</td>
        <td>{{ revision.userName }}</td>
        <td>
          <a
            [routerLink]="routerLinks[revision.type]"
            [queryParams]="{ id: revision.typeId, revisionId: revision.id }"
            [state]="revisionStates[revision.id]"
            (click)="navigateToRevision()"
          >
            {{ revision.revisionComment }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
