import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { PreSignedStorageResponse, RecommendedTile, StorageRequest } from '@shared/types';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, firstValueFrom, map, switchMap, throwError } from 'rxjs';
import { v4 } from 'uuid';
import { ConfigService } from '../shared/config.service';
// import { AuthService } from './auth.service';

interface Image {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class RecommendedService implements OnDestroy {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
    public oidcSecurityService: OidcSecurityService, // public AuthService: AuthService,
  ) {}

  public clientId = this.config.app.secrets.clientId;
  messageId = () => v4();
  public url = this.config.app.secrets.url;
  public $recommendedTiles = this.getRecommendedTiles();
  private subscriptions: Subscription = new Subscription();

  async getImageStorageUrl(file: File): Promise<PreSignedStorageResponse> {
    const body = { fileName: file.name, contentType: file.type };

    const accessToken = await firstValueFrom(this.oidcSecurityService.getAccessToken());

    const observable = this.http.post<PreSignedStorageResponse>(this.url + '/get-image-storage-url', body, {
      headers: {
        'client_id': this.clientId,
        'X-NW-Message-ID': this.messageId(),
        'Authorization': 'Bearer ' + accessToken,
      },
    });

    return firstValueFrom(observable);
  }

  async saveImage(request: StorageRequest) {
    return firstValueFrom(
      this.http.put(request.url, request.buffer, {
        headers: {
          'Content-Type': request.contentType,
          'Content-Encoding': 'base64',
          'x-amz-server-side-encryption': 'AES256',
        },
      }),
    );
  }

  saveRecommendedTiles(recommendedTile: RecommendedTile, revisionComment: string): Observable<RecommendedTile> {
    this.getUserId(recommendedTile);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          if (recommendedTile.id) {
            recommendedTile.revisionComment = revisionComment;
            return this.http.put<RecommendedTile>(this.url + `/recommended-tiles/${recommendedTile.id}`, recommendedTile, {
              headers: {
                'client_id': this.clientId,
                'X-NW-Message-ID': this.messageId(),
                'Authorization': 'Bearer ' + accessToken,
              },
            });
          } else {
            return this.http.post<RecommendedTile>(this.url + '/recommended-tiles', recommendedTile, {
              headers: {
                'client_id': this.clientId,
                'X-NW-Message-ID': this.messageId(),
                'Authorization': 'Bearer ' + accessToken,
              },
            });
          }
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getRecommendedTiles(): Observable<RecommendedTile[]> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http
            .get<RecommendedTile[]>(this.url + '/admin/recommended-tiles', {
              headers: {
                'client_id': this.clientId,
                'X-NW-Message-ID': this.messageId(),
                'Authorization': 'Bearer ' + accessToken,
              },
            })
            .pipe(
              map((recommendedTiles) =>
                recommendedTiles.sort((a, b) => (a.recommendedTileName.toUpperCase() > b.recommendedTileName.toUpperCase() ? 1 : -1)),
              ),
            );
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  deleteRecommendedTile(recommendedTile: RecommendedTile): Observable<RecommendedTile> {
    this.getUserId(recommendedTile);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.delete<RecommendedTile>(this.url + `/recommended-tiles/${recommendedTile.id}`, {
            body: recommendedTile,
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getImages(): Observable<string[]> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.get<string[]>(this.url + `/get-images`, {
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getImage(fileName: string): Observable<Image> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.get<Image>(this.url + `/get-icon-url?fileName=${fileName}`, {
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getUserId(recommendedTile: RecommendedTile) {
    this.subscriptions.add(
      this.oidcSecurityService.getUserData().subscribe((userData) => {
        recommendedTile.userName = userData.displayName;
        recommendedTile.userId = userData.userId;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
